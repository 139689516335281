<!-- 成绩复核列表 -->
<style type="text/css">
@import "~@/assets/css/common.css";
</style>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { examReviewList, examReviewRemove, getLibraryInfo } from "@/api/grade";
import { format } from "@/libs/common";
/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "成绩复核列表",
      items: [
        {
          text: "成绩查询",
        },
        {
          text: "成绩复核",
          active: true,
        },
      ],
      recordList: [],
    };
  },
  methods: {
    deleteFH(index) {
      let data = this.recordList[index];
      getLibraryInfo(data.cjkid).then((res) => {
        if (res.status) {
          res.data.fhkssj = format(res.data.fhkssj, "yyyy-MM-dd");
          res.data.fhjssj = format(res.data.fhjssj, "yyyy-MM-dd");
          if (
            new Date(res.data.fhkssj) < new Date() &&
            new Date(res.data.fhjssj) > new Date()
          ) {
            this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }).then(() => {
              examReviewRemove(data.sid).then((res) => {
                if (res.status) {
                  this.$message({
                    type: "success",
                    message: "撤销成功!",
                  });
                  this.getExamReviewList();
                }
              });
            });
          }else{
            this.$message({
                    type: "warning",
                    message: "复核时间已结束，撤销失败!",
                  });
          }
        }
      });
    },
    InfoFH() {},
    getExamReviewList() {
      examReviewList().then((res) => {
        if (res.status) {
          this.recordList = res.data;
        }
      });
    },
  },
  created() {
    this.getExamReviewList();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 470px">
          <div class="card-body">
            <h4 class="card-title pb-3 border-dash flexList">
              <span class="mr-2">以下是为您已提交的成绩复核列表</span>
              <!-- <a href="/scoreCheck"
                ><button type="button" class="btn btn-info h30 w-md">
                  <i class="iconfont icon-plus-circle mr-2 font-size-18"></i
                  >提交复核申请
                </button></a
              > -->
            </h4>
            <div class="table-responsive border">
              <table
                class="table table-has-bg light-table table-hover table-bordered"
              >
                <thead class="thead-light">
                  <tr>
                    <th style="width: 4%">序号</th>
                    <th>考试名称</th>
                    <th>复核科目</th>
                    <th style="width: 6%">复核前成绩</th>
                    <th style="width: 6%">复核后成绩</th>
                    <th style="width: 15%">提交时间</th>
                    <th style="width: 6%">复核状态</th>
                    <th style="width: 5%">操作</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in recordList" :key="index">
                    <th scope="row">{{ index + 1 }}</th>
                    <td>{{ item.ksmc }}</td>
                    <td>
                      <a href="javascript:;" class="blue-font">{{
                        item.kmmc
                      }}</a>
                    </td>
                    <td>{{ item.cj }}</td>
                    <td>{{ item.fhjg == 2? item.fhcj:'' }}</td>
                    <td>{{ item.sqsj }}</td>
                    <td>
                      <div v-if="item.cxfh == 1" class="text-danger">
                        已撤销
                      </div>
                      <div
                        v-if="item.cxfh == 0"
                        :class="{
                          'text-secondary': item.fhjg == 0,
                          'text-info': item.fhjg == 1,
                          'text-success': item.fhjg == 2,
                        }"
                      >
                        {{
                          item.fhjg == 0
                            ? "成绩复核中"
                            : item.fhjg == 1
                            ? "成绩复核中"
                            : "已复核"
                        }}
                      </div>
                    </td>
                    <td>
                      <button
                        type="button"
                        class="btn btn-danger h30"
                        @click="deleteFH(index)"
                        v-if="item.fhjg == 0 && item.cxfh != 1"
                      >
                        撤销
                      </button>
                      <!-- <button
                        type="button"
                        class="btn btn-primary h30"
                        @click="InfoFH(index)"
                        v-if="item.fhjg != 0 && item.cxfh != 1"
                      >
                        详情
                      </button> -->
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
